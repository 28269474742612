import React from "react";
import AppImage from "./AppImage";

const ImageBackground = ({ resizeMode = 'fill', style = {}, source, children, ...otherProps }) => {
  // Ensure style has a default position
  const combinedStyle = {
    ...style,
    position: 'relative',
  };

  return (
    <div {...otherProps} style={combinedStyle}>
      {source ? (
        <AppImage
          resizeMode={resizeMode}
          source={source}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
      {children}
    </div>
  );
};

export default React.memo(ImageBackground);
