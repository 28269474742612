import {Scene} from 'phaser';
import {GSUEventBus} from "src/gameSnakeUp/GameSnakeUp";
import {addTimerEvent, checkOverlap} from "src/game/GameHelper";
import utils from "src/js/utils/utils";

const BOX_PER_ROW = 3;
const LINE_PER_BOX_WIDTH = 3;
const MOVE_UP_SNAKE_FACE = 0.35;
const MOVE_UP_SNAKE_BODY = 0.3;
const SNAKE_SHAKE_UP_TIME = 400;
const SNAKE_SHAKE_UP_VELOCITY = 300;
const SHOW_BOXES_AFTER = 2;

export class GSUGame extends Scene {

  constructor() {
    super('GSUGame');
  }

  onEventRestart = () => {
    this.cleanup();
    if (!this.game.options.simulation) {
      this.scene.stop('GSUGame');
      this.scene.start('GSUGame');
    }
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioPlus) {
      this.audioPlus.setMute(mute);
    }
    if (this.audioMinus) {
      this.audioMinus.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  onEventSimulate = () => {
    this.inAutoSimulation = true;
    if (this.textHint) {
      this.textHint.setVisible(false);
    }
    if (this.textHintDetail) {
      this.textHintDetail.setVisible(false);
    }
    addTimerEvent(this, 1000, () => {
      this.autoPlay();
    });
  }

  autoPlay = () => {
    if (!this.gameEnded) {
      this.onSwipeStart();
    }
  }

  cleanup = () => {
    GSUEventBus.off('mute', this.onEventMute);
    GSUEventBus.off('restart', this.onEventRestart);
    GSUEventBus.off('simulate', this.onEventSimulate);
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.input.off('pointerdown');
    this.time.removeAllEvents();
    this.events.off('destroy');
  }

  create() {
    this.events.on('destroy', this.cleanup);
    GSUEventBus.on('mute', this.onEventMute);
    GSUEventBus.on('restart', this.onEventRestart);
    GSUEventBus.on('simulate', this.onEventSimulate);

    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'انقر لتبدأ' : 'Click to Start';
    const stringHintDetail = this.game.options.locale && this.game.options.locale == 'ar' ? 'انقر على يسار/يمين الثعبان لتوجيهه.' : 'Click left/right of snake to guide it.';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.gameEnded = false;
    this.score = 0;
    this.scoreToWin = this.game.options.scoreToWin || 10;
    this.segmentCounter = 0;
    this.isGameStarted = false;
    this.raceEndDisplayed = false;
    this.turns = [];
    this.snakeXs = [];
    this.tempSnakeDirPart = {};
    this.tempSnakeParts = [];
    this.endReached = false;
    this.isHolding = false;
    this.isCollided = false;
    this.isGameStarted = false;
    this.raceEndDisplayed = false;
    this.boxesValueMaxIndexes = [];
    this.boxOverlapCounter = 0;
    this.snakeBoxPassCounter = 0;

    this.roadWidth = gameWidth - 34;
    this.boxSize = this.roadWidth / BOX_PER_ROW;
    this.roadSegmentHeight = 2 * this.boxSize;
    this.lineSpace = this.roadWidth/BOX_PER_ROW/LINE_PER_BOX_WIDTH;

    this.snakeWidth = this.boxSize/LINE_PER_BOX_WIDTH;
    const bgMargin = (gameWidth - this.roadWidth)/2;
    for (let i = 0; i < BOX_PER_ROW * LINE_PER_BOX_WIDTH; i++) {
      this.snakeXs.push(Math.floor(bgMargin + i * this.lineSpace + this.snakeWidth/2));
    }

    this.snakeData = {
      bodyParts: [],
      activeBodyPartIndexes: new Set(),
    };

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_bg', {loop: true})
        .setMute(this.game.options.mute);
      this.audioGameBg.play();
      this.audioPlus = this.sound.add('audio_plus').setMute(this.game.options.mute)
        .setVolume(0.4);
      this.audioMinus = this.sound.add('audio_minus').setMute(this.game.options.mute)
        .setVolume(0.6);
      this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute)
        .setVolume(0.4);
      this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute)
        .setVolume(0.7);
    }

    this.bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    this.bgImage.setDisplaySize(gameWidth, gameHeight);

    this.progressGame = this.add.rectangle(0, 0, 0, 14, 0xFFFFFF)
      .setOrigin(0, 0)
      .setAlpha(0.5)
      .setDepth(1);
    this.textScore = this.add.text(gameWidth / 2, 68, '0', {
      // fontFamily: 'Poppins',
      fontSize: 45, color: '#000',
      stroke: '#666', strokeThickness: 2,
      align: 'center',
      backgroundColor: '#ffffffEE',
      padding: {
        left: 20,
        right: 20,
        top: 4,
        bottom: 2
      },
      cornerRadius: 4,
    }).setOrigin(0.5)
      .setDepth(2)
      .setShadow(0, 0, 0xffffff, 4);

    // Set camera to move infinitely on the Y-axis
    this.cameras.main.scrollY = 0;

    const snakeXIndex = Math.floor(this.snakeXs.length/2);
    this.snakeHeight = this.snakeWidth*1.2;
    this.snakeFace = this.physics.add.image(this.snakeXs[snakeXIndex], gameHeight*.65, 'snakeFace')
      .setDisplaySize(this.snakeWidth, this.snakeHeight)
      .setBounce(1, 1)
      .setDepth(1000);
    this.snakeFace.setBodySize(this.snakeFace.body.width, this.snakeFace.body.height *.90);
    this.snakeFace.xIndex = snakeXIndex;
    this.addSnakeBodyPart();
    this.addSnakeBodyPart();

    this.roadGraphics = this.add.graphics();
    for (let i = 0; i < Math.ceil(gameHeight/this.roadSegmentHeight); i++) {
      this.drawRoadSegment(gameHeight - (i+1) * this.roadSegmentHeight);
    }

    if (!this.game.options.simulation) {
      this.textHint = this.add.text(gameWidth / 2, gameHeight - 180, stringHint, {
        fontFamily: 'Poppins',
        fontSize: 45, color: '#ffffff',
        stroke: '#48464F', strokeThickness: 2,
        align: 'center'
      }).setOrigin(0.5)
        .setAlpha(0.9);
      this.textHintDetail = this.add.text(gameWidth / 2, gameHeight - 135, stringHintDetail, {
        fontFamily: 'Poppins',
        fontSize: 22, color: '#ffffff',
        stroke: '#48464F', strokeThickness: 2,
        align: 'center'
      }).setOrigin(0.5)
        .setAlpha(0.9);

      this.gameOverText = this.add.text(gameWidth / 2, 180, this.stringGameOver, {
        // fontFamily: 'Poppins',
        fontSize: 68, color: '#666',
        backgroundColor: '#eee',
        stroke: '#000', strokeThickness: 4,
        align: 'center', padding: 24,
      }).setOrigin(0.5).setVisible(false).setDepth(2);
    }

    this.readyToPlayGame();
  }

  update() {
    if (this.gameEnded) {
      return null;
    }

    const gameHeight = this.game.config.height;

    let delta = 5;
    if (this.isHolding) {
      this.cameras.main.scrollY -= delta;
      this.snakeFace.setY(this.snakeFace.y - delta);
      this.bgImage.setY(this.bgImage.y - delta);
      this.textScore.setY(this.textScore.y - delta);
      if (this.gameOverText) {
        this.gameOverText.setY(this.gameOverText.y - delta);
      }
      this.progressGame.setY(this.progressGame.y - delta);
      this.updateSnakeParts();
      this.updateProgress();

      if ((this.segmentCounter - 2) * this.roadSegmentHeight < Math.abs(this.cameras.main.scrollY) + gameHeight) {
        this.drawRoadSegment(gameHeight - (this.segmentCounter+1) * this.roadSegmentHeight);
      }

      let index = 0;
      this.tempSnakeParts.forEach((tempSnakePart) => {
        tempSnakePart.setVisible(false);
        this.tempSnakeParts.splice(index, 1);
        index++;
      });

      if (this.inAutoSimulation) {
        if (!this.isCollided && this.boxOverlapCounter < this.boxesValueMaxIndexes.length && this.boxOverlapCounter === this.snakeBoxPassCounter) {
          this.snakeBoxPassCounter++;
          const minMaxIndexes = this.boxesValueMaxIndexes[this.boxOverlapCounter];
          const snakePosPerIndex = this.snakeXs.length / 3;
          const maxIndex = minMaxIndexes.maxValueIndex * snakePosPerIndex + Math.floor(snakePosPerIndex/2);
          const oldIndex = this.snakeFace.xIndex;
          let newIndex = null;
          if (this.snakeBoxPassCounter < 5) {
            newIndex = maxIndex;
          } else {
            newIndex = Math.floor((Math.random() * 100)) % this.snakeXs.length;
          }
          const moves = Math.min(4, Math.abs(oldIndex - newIndex));
          const direction = oldIndex < newIndex ? 1 : oldIndex > newIndex ? -1 : 0;
          const boxOverlapCounter = this.boxOverlapCounter;
          addTimerEvent(this, 300, () => {
            this.moveSnakeAuto(boxOverlapCounter, direction, moves);
          });
        }
      }
    }
  }

  moveSnakeAuto = (boxOverlapCounter, direction, moves) => {
    if (boxOverlapCounter === this.boxOverlapCounter) {
      addTimerEvent(this, 400, () => {
        this.processClick(direction, this.snakeFace.y - 100);
        if (moves > 0) {
          this.moveSnakeAuto(boxOverlapCounter, direction, moves - 1);
        }
      });
    }
  }

  updateProgress = () => {
    if (this.progressGame) {
      const scorePer = Math.min(100, 1 + Math.floor((Math.abs(this.cameras.main.scrollY) / (this.scoreToWin * SHOW_BOXES_AFTER * this.roadSegmentHeight + this.roadSegmentHeight)) * 100));
      this.progressGame.width = Math.floor(this.game.config.width * scorePer / 100);
    }
  }

  addDummySnakePart = (snakeBodyPart) => {
    const x = snakeBodyPart.x - snakeBodyPart.tempColliderDirection * this.lineSpace * .5;
    const y = snakeBodyPart.y + snakeBodyPart.displayHeight * .2;
    const tempPart = this.add.image(x, y, 'snakeBody1')
      .setDisplaySize(this.snakeWidth, this.snakeWidth)
      .setDepth(snakeBodyPart.depth - 1);
    this.tempSnakeParts.push(tempPart);
  }

  addSnakeBodyPart = () => {
    let lastPart = null;
    if (this.snakeData.activeBodyPartIndexes.size > 0) {
      let lastIndex;
      for (const item of this.snakeData.activeBodyPartIndexes) {
        lastIndex = item;
      }
      lastPart = this.snakeData.bodyParts[lastIndex];
    }
    let heightFactor = MOVE_UP_SNAKE_BODY;
    if (!lastPart) {
      lastPart = this.snakeFace;
      heightFactor = MOVE_UP_SNAKE_FACE;
    }

    let sawIndex = -1;
    for (let i = 0; i < this.snakeData.bodyParts.length; i++) {
      if (!this.snakeData.activeBodyPartIndexes.has(i)) {
        sawIndex = i;
        break;
      }
    }
    const y = lastPart.y + lastPart.displayHeight * heightFactor;
    const x = this.snakeXs[lastPart.xIndex];
    let snakePart = null;
    if (sawIndex === -1) {
      snakePart = this.physics.add.image(x, y, this.snakeData.bodyParts.length % 2 === 0 ? 'snakeBody1' : 'snakeBody1').setDepth(1);
      snakePart.setDisplaySize(this.snakeWidth, this.snakeWidth);
      snakePart.setBodySize(this.snakeWidth * .9, this.snakeWidth * .9);
      this.snakeData.bodyParts.push(snakePart);
      sawIndex = this.snakeData.bodyParts.length - 1;
    } else {
      snakePart = this.snakeData.bodyParts[sawIndex];
      snakePart.setPosition(x, y);
      snakePart.setVisible(true);
    }
    snakePart.name = this.snakeData.activeBodyPartIndexes.size + 1;
    snakePart.setDepth(1000 - (this.snakeData.activeBodyPartIndexes.size + 1));
    snakePart.xIndex = lastPart.xIndex;
    if (lastPart.allColliders) {
      lastPart.allColliders.forEach((overlapData) => {
        const collider = this.physics.add.overlap(snakePart, overlapData.turnDirection, overlapData.overlapCallback);
        if (!snakePart.allColliders) {
          snakePart.allColliders = [];
        }
        snakePart.allColliders.push({y: overlapData.y, turnDirection: overlapData.turnDirection, overlapCallback: overlapData.overlapCallback, collider: collider});
      });
    }
    if (sawIndex >= 0) {
      this.snakeData.activeBodyPartIndexes.add(sawIndex);
    }
  }

  removeSnakeBodyParts = () => {
    if (this.snakeData.activeBodyPartIndexes.size > 0) {
      let lastIndex;
      for (const item of this.snakeData.activeBodyPartIndexes) {
        lastIndex = item;
      }
      this.snakeData.activeBodyPartIndexes.delete(lastIndex);
      const snakePart = this.snakeData.bodyParts[lastIndex];
      snakePart.setPosition(-this.snakeWidth, -this.snakeHeight);
      snakePart.setVisible(false);
      if (snakePart.allColliders) {
        snakePart.allColliders.forEach((collider) => {
          collider.active = false;
        });
      }
      snakePart.allColliders = null;
    }
  }

  updateSnakeParts = () => {
    let heightFactor = MOVE_UP_SNAKE_FACE;
    let y = this.snakeFace.y + this.snakeFace.displayHeight * heightFactor;
    this.snakeData.activeBodyPartIndexes.forEach((index) => {
      const snakePart = this.snakeData.bodyParts[index];
      snakePart.setY(y);
      snakePart.setVisible(true);

      heightFactor = MOVE_UP_SNAKE_BODY;
      y = snakePart.y + snakePart.displayHeight * heightFactor;
    });
  }

  shakeSnake = (direction, snakeY, otherObject) => {
    this.snakeFace.setVelocityY(-SNAKE_SHAKE_UP_VELOCITY * direction);
    otherObject.setAlpha(0.6);
    otherObject.textView.setAlpha(0.6);

    addTimerEvent(this, SNAKE_SHAKE_UP_TIME/2 - 20, () => {
      this.snakeFace.setVelocityY(SNAKE_SHAKE_UP_VELOCITY * direction);
      otherObject.setAlpha(1);
      otherObject.textView.setAlpha(1);

      addTimerEvent(this, SNAKE_SHAKE_UP_TIME/2 - 20, () => {
        this.snakeFace.setVelocityY(0);
        this.snakeFace.y = snakeY;
      });
    });
  }

  drawRoadSegment = (y) => {
    if (this.raceEndDisplayed) {
      return;
    }

    const gameWidth = this.game.config.width;
    this.segmentCounter++;

    const center = gameWidth/2;
    const bgMargin = (gameWidth - this.roadWidth)/2;
    // gray bg
    this.roadGraphics.fillStyle('#475164', 1);
    this.roadGraphics.fillStyle(0x475164, 1);
    this.roadGraphics.fillRect(center - this.roadWidth/2, y, this.roadWidth, this.roadSegmentHeight);
    // vertical lines
    this.roadGraphics.fillStyle(0xffffff, 0.05);
    for (let i = 0; i < BOX_PER_ROW * LINE_PER_BOX_WIDTH + 1; i++) {
      this.roadGraphics.fillRect(bgMargin + i * this.lineSpace - 1, y, 2, this.roadSegmentHeight);
    }
    // horizontal lines
    this.roadGraphics.fillStyle(0xffffff, 0.02);
    for (let i = 0; i < this.roadSegmentHeight/this.boxSize * LINE_PER_BOX_WIDTH; i++) {
      this.roadGraphics.fillRect(bgMargin, y + i * this.lineSpace - 1, this.roadWidth, 2);
    }

    const drawBoxes = this.segmentCounter > 1 && this.segmentCounter % SHOW_BOXES_AFTER === 0;
    if (drawBoxes) {
      const drawEnd = this.segmentCounter > SHOW_BOXES_AFTER * this.scoreToWin + 1;
      if (drawEnd) {
        this.raceEndDisplayed = true;
        const width = this.roadWidth;
        const height = this.roadWidth * .05;
        const endView = this.physics.add.image(center, y, 'finish')
          .setDisplaySize(width, height);
        endView.setBodySize(endView.body.width, endView.body.height *.2);
        this.physics.add.overlap(this.snakeFace, endView, this.onReachFinish);
      } else {
        const boxOuterWidth = this.roadWidth/BOX_PER_ROW;
        let boxPositions = [
          {image: 'box1', value: Phaser.Math.Between(0, 5)},
          {image: 'box2', value: Phaser.Math.Between(0, 5)},
        ];
        for (let i = 2; i < BOX_PER_ROW + 1; i++) {
          boxPositions.push({image: 'boxMinus', value: Phaser.Math.Between(0, -5)});
        }
        if (this.segmentCounter >= 4) {
          boxPositions = utils.shuffleArray(boxPositions);
        }
        // remove extra box
        boxPositions.splice(boxPositions.length - 1, 1);

        let index = 0;
        let maxValue = -1000;
        let maxValueIndex = 0;
        let minValue = 1000;
        let minValueIndex = 0;
        boxPositions.forEach((box) => {
          const boxX = bgMargin + index * boxOuterWidth + this.boxSize/2;
          const boxView = this.add.image(boxX, y + this.boxSize/2, box.image)
            .setDisplaySize(this.boxSize, this.boxSize);

          const boxValueWidth = this.boxSize *.8;
          const boxValueHeight = this.boxSize *.3;
          const boxValueY = y + this.boxSize + boxValueHeight*.2;
          const boxValueView = this.physics.add.image(boxX, boxValueY, box.value < 0 ? 'minus' : 'plus')
            .setDisplaySize(boxValueWidth, boxValueHeight);
          boxValueView.setBodySize(boxValueView.body.width * .9, boxValueView.body.height *.4);
          boxValueView.boxView = boxView;
          boxValueView.value = box.value;
          boxValueView.index = this.segmentCounter;
          boxValueView.collider = this.physics.add.overlap(this.snakeFace, boxValueView, this.onBoxOverlap);

          boxValueView.textView = this.add.text(boxX, boxValueY, '', {
            fontFamily: 'Poppins',
            fontSize: 34, color: '#ffffff',
            stroke: '#48464F', strokeThickness: 2,
            align: 'center'
          }).setOrigin(0.5);
          this.updateBoxValue(boxValueView.textView, box.value);

          if (box.value > maxValue) {
            maxValue = box.value;
            maxValueIndex = index;
          }
          if (box.value < minValue) {
            minValue = box.value;
            minValueIndex = index;
          }
          index++;
        });
        this.boxesValueMaxIndexes.push({maxValueIndex, minValueIndex});
      }
    }
  }

  updateBoxValue = (textView, value) => {
    textView.setText(value > 0 ? '+'+ value : value);
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onSwipeStart);
    // this.input.on('pointermove', this.onSwipeMove);
    // this.input.on('pointerup', this.onSwipeEnd);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onSwipeStart);
    // this.input.off('pointermove', this.onSwipeMove);
    // this.input.off('pointerup', this.onSwipeEnd);
  }

  onSwipeMove = (pointer) => {
  }

  onSwipeStart = (pointer) => {
    if (!this.isGameStarted) {
      this.isGameStarted = true;
      this.isHolding = true;
      if (this.textHint) {
        this.textHint.setVisible(false);
      }
    } else {
      let direction = 0;
      if (pointer.downX > this.snakeFace.x) {
        direction = 1;
      } else if (pointer.downX < this.snakeFace.x) {
        direction = -1;
      }
      if (direction !== 0) {
        this.processClick(direction, pointer.downY);
      }
    }
  }

  onSwipeEnd = (pointer) => {
    const swipeXAbs = pointer.upX - pointer.downX;
    let direction = 0;
    if (swipeXAbs > 1) {
      direction = 1;
    } else if (swipeXAbs < -1) {
      direction = -1;
    }
    if (direction !== 0) {
      this.processClick(direction, pointer.upY);
    }
  }

  processClick = (direction, upY) => {
    if (this.gameEnded || !this.isGameStarted || !this.isHolding || this.clickInProgress) {
      return;
    }
    if (!this.snakeFace.hasVelocityX && direction !== 0) {
      this.clickInProgress = true;
      const timeToShift = this.lineSpace*2;
      addTimerEvent(this, timeToShift+100, () => {
        this.clickInProgress = false;
      });

      let turnOverlap = null;
      if (direction > 0 && this.snakeFace.xIndex < this.snakeXs.length - 1) {
        turnOverlap = this.onTurnRightOverlap;
      } else if (direction < 0 && this.snakeFace.xIndex > 0) {
        turnOverlap = this.onTurnLeftOverlap;
      }
      if (!turnOverlap) {
        return;
      }

      this.detachPointers();
      this.snakeFace.hasVelocityX = 1;
      const turn = this.physics.add.staticImage(this.snakeXs[this.snakeFace.xIndex], this.snakeFace.y - this.snakeFace.displayHeight, 'snakeBody1').setAlpha(0.1).setDepth(1);
      turn.setBodySize(this.snakeWidth * .9, this.snakeFace.displayHeight * .9);
      turn.setVisible(false);
      turn.name = Date.now();
      this.turns.splice(0, 0, turn);
      turn.setBodySize(turn.body.width * .5, turn.body.height *.5);
      const collider = this.physics.add.overlap(this.snakeFace, turn, turnOverlap);
      if (!this.snakeFace.allColliders) {
        this.snakeFace.allColliders = [];
      }
      this.snakeFace.allColliders.push({y: upY, turnDirection: turn, overlapCallback: turnOverlap, collider: collider});

      this.snakeData.activeBodyPartIndexes.forEach((index) => {
        const bodyPart = this.snakeData.bodyParts[index];
        const collider = this.physics.add.overlap(bodyPart, turn, turnOverlap);
        if (!bodyPart.allColliders) {
          bodyPart.allColliders = [];
        }
        bodyPart.allColliders.push({y: upY, turnDirection: turn, overlapCallback: turnOverlap, collider: collider});
      });
    }
  }

  onReachFinish = (snake, otherObject) => {
    if (!this.endReached) {
      this.endReached = true;
      this.endGame(true);
    }
  }

  onTurnRightOverlap = (snakeBodyPart, otherObject) => {
    this.onTurnOverlap(1, snakeBodyPart, otherObject);
  }

  onTurnLeftOverlap = (snakeBodyPart, otherObject) => {
    this.onTurnOverlap(-1, snakeBodyPart, otherObject);
  }

  onTurnOverlap = (direction, snakeBodyPart, otherObject) => {
    if (checkOverlap(snakeBodyPart, otherObject) && (!snakeBodyPart.tempColliderNames || !snakeBodyPart.tempColliderNames.has(otherObject.name))) {
      if (!snakeBodyPart.tempColliderNames) {
        snakeBodyPart.tempColliderNames = new Set();
      }
      const colliderName = otherObject.name;
      snakeBodyPart.tempColliderNames.add(colliderName);
      // snakeBodyPart.tempColliderName = otherObject.name;
      snakeBodyPart.tempColliderDirection = direction;
      otherObject.tempColliderSnakePartName = snakeBodyPart.name;

      const velocityX = 500;
      const timeToShift = this.lineSpace*2;

      snakeBodyPart.setVelocityX(velocityX * direction);
      if (snakeBodyPart === this.snakeFace) {
        snakeBodyPart.setAngularVelocity(200 * direction);
        addTimerEvent(this, timeToShift/2, () => {
          snakeBodyPart.setAngularVelocity(-60 * direction);
        });
      }

      addTimerEvent(this, timeToShift, () => {
        snakeBodyPart.xIndex = snakeBodyPart.xIndex + direction;
        snakeBodyPart.x = this.snakeXs[snakeBodyPart.xIndex];
        snakeBodyPart.setVelocityX(0);
        if (snakeBodyPart === this.snakeFace) {
          snakeBodyPart.setAngularVelocity(0);
          snakeBodyPart.angle = 0;
          snakeBodyPart.hasVelocityX = 0;
          this.attachPointers();
        }

        // add dummy snake part to show the snake continous while hiting to box
        if ((this.isCollided || this.endReached) && otherObject.tempColliderSnakePartName === snakeBodyPart.name) {
          const tempColliderName1 = colliderName + '1';
          const tempColliderName2 = colliderName + '2';

          const temp1X = snakeBodyPart.x - direction * this.lineSpace * .7;
          const temp1Y = snakeBodyPart.y + snakeBodyPart.displayHeight * .2;
          if (this.tempSnakeDirPart[tempColliderName1]) {
            this.tempSnakeDirPart[tempColliderName1].setPosition(temp1X, temp1Y)
          } else {
            this.tempSnakeDirPart[tempColliderName1] = this.add.image(temp1X, temp1Y, 'snakeBody1')
              .setDisplaySize(this.snakeWidth, this.snakeWidth);
            this.tempSnakeParts.push(this.tempSnakeDirPart[tempColliderName1]);
          }
          this.tempSnakeDirPart[tempColliderName1].setVisible(true);
          this.tempSnakeDirPart[tempColliderName1].setDepth(snakeBodyPart.depth - 1);
          if (snakeBodyPart.name == this.snakeData.activeBodyPartIndexes.size) {
            this.tempSnakeDirPart[tempColliderName1].setVisible(false);
          }

          const temp2X = snakeBodyPart.x - direction * this.lineSpace * .35;
          const temp2Y = snakeBodyPart.y + snakeBodyPart.displayHeight * .1;
          if (this.tempSnakeDirPart[tempColliderName2]) {
            this.tempSnakeDirPart[tempColliderName2].setPosition(temp2X, temp2Y)
          } else {
            this.tempSnakeDirPart[tempColliderName2] = this.add.image(temp2X, temp2Y, 'snakeBody1')
              .setDisplaySize(this.snakeWidth, this.snakeWidth);
            this.tempSnakeParts.push(this.tempSnakeDirPart[tempColliderName2]);
          }
          this.tempSnakeDirPart[tempColliderName2].setVisible(true);
          this.tempSnakeDirPart[tempColliderName2].setDepth(snakeBodyPart.depth - 1);
          if (snakeBodyPart.name == this.snakeData.activeBodyPartIndexes.size) {
            this.tempSnakeDirPart[tempColliderName2].setVisible(false);
          }
        }
      });
    }
  }

  onBoxOverlap = (snake, otherObject) => {
    if (!this.isCollided && !this.gameEnded && this.lastCollideSegmentCounter !== otherObject.index) {
      this.lastCollideSegmentCounter = otherObject.index;
      this.boxOverlapCounter++;
      this.isCollided = true;
      this.isHolding = false;
      this.detachPointers();
      otherObject.collider.active = false;
      const snakeY = snake.y;

      const value = Math.abs(otherObject.value);
      for (let i = 0; i < value; i++) {
        addTimerEvent(this, i * SNAKE_SHAKE_UP_TIME, () => {
          this.processBoxOverlap(snake, snakeY, otherObject);
        });
      }
      addTimerEvent(this, value * SNAKE_SHAKE_UP_TIME, () => {
        if (!this.gameEnded) {
          this.score++;
          this.textScore.setText(this.score);
          this.isCollided = false;
          this.isHolding = true;
          this.attachPointers();
          this.snakeFace.setVelocityY(0);
          this.snakeFace.y = snakeY;
          otherObject.setVisible(false);
          otherObject.textView.setVisible(false);
          otherObject.boxView.setVisible(false);
        }
      });
    }
  }

  processBoxOverlap = (snake, snakeY, otherObject) => {
    if (!this.gameEnded) {
      if (otherObject.value > 0) {
        if (this.audioPlus) {
          this.audioPlus.play();
        }
        otherObject.value--;
        this.updateBoxValue(otherObject.textView, otherObject.value);
        this.addSnakeBodyPart();
        this.shakeSnake(1, snakeY, otherObject);
      } else if (otherObject.value < 0) {
        if (this.snakeData.activeBodyPartIndexes.size <= 0) {
          // game over
          this.snakeFace.setAlpha(0.7);
          this.endGame(false);
        } else {
          if (this.audioMinus) {
            this.audioMinus.play();
          }
          otherObject.value++;
          this.updateBoxValue(otherObject.textView, otherObject.value);
          this.removeSnakeBodyParts();
          this.shakeSnake(-1, snakeY, otherObject);
        }
      }
    }
  }

  readyToPlayGame = () => {
    if (!this.gameEnded) {
      this.attachPointers();
    }
  }

  endGame = (isWin) =>  {
    this.gameEnded = true;
    this.isHolding = false;
    this.detachPointers();

    isWin = isWin && this.score >= this.scoreToWin;
    if (isWin) {
      if (this.audioGameWin) {
        this.audioGameWin.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringWellDone);
      }
    } else {
      if (this.audioGameEnd) {
        this.audioGameEnd.play();
      }
      if (this.gameOverText) {
        this.gameOverText.setText(this.stringGameOver);
      }
    }

    addTimerEvent(this, 1000, () => {
      if (this.gameOverText) {
        this.gameOverText.setVisible(true);
      }
      addTimerEvent(this, 1000, () => {
        GSUEventBus.emit('eventOnEnd', this.score);
      });
    });
  }
}
