import {Scene} from 'phaser';

export class GRPreloader extends Scene {
  constructor() {
    super('GRPreloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);
    const dynamicColor = Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color; // Convert hex string to Phaser color
    const dynamicAlpha = 0.2;
    const graphics = this.add.graphics();
    graphics.fillStyle(dynamicColor, dynamicAlpha);
    graphics.fillRect(0, 0, gameWidth, gameHeight);

    this.add.text(gameWidth / 2, gameHeight / 2, this.game.options.name, {
      fontSize: 72,
      color: this.game.options.themeColor,
      align: 'center',
      padding: 12,
    }).setOrigin(0.5, 0.5)
      .setWordWrapWidth(gameWidth-24)
      .setShadow(0, 0, this.game.options.themeColor, 72, false, true);

    const barWidth = gameWidth - 200;
    const innerBarWidth = barWidth - 16;
    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight - 200, barWidth, 40).setStrokeStyle(4, Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color);
    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight - 200, 4, 30, Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {
      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);
    });
  }

  preload() {
    let bgAudioUrl = null;
    let adImage1Url = null;
    let adImage2Url = null;
    let adImage3Url = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              adImage1Url = item.url;
            } else if (item.number === 1) {
              adImage2Url = item.url;
            } else if (item.number === 2) {
              adImage3Url = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('assets/gameRoshambo');

    this.load.image('adImage1', adImage1Url || 'adImage1.png');
    this.load.image('adImage2', adImage2Url || 'adImage2.png');
    this.load.image('adImage3', adImage3Url || 'adImage3.png');

    this.load.image('rockR', 'rockR.png');
    this.load.image('paperR', 'paperR.png');
    this.load.image('scissorR', 'scissorR.png');
    this.load.image('rock', 'rock.png');
    this.load.image('paper', 'paper.png');
    this.load.image('scissor', 'scissor.png');

    if (!options.simulation) {
      this.load.audio('audio_bg', bgAudioUrl || 'audios/audio_bg.mp3');
      this.load.audio('audio_hit', 'audios/audio_hit.mp3');
      this.load.audio('audio_miss', 'audios/audio_miss.mp3');
      this.load.audio('audio_draw', 'audios/audio_draw.mp3');
      this.load.audio('audio_game_end', 'audios/audio_game_end.mp3');
      this.load.audio('audio_game_win', 'audios/audio_game_win.mp3');
    }
  }

  create() {
    this.scene.start('GRGame');
  }
}
