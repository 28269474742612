import * as types from '../constants/actionTypes';
import {wrapCall} from '../utils/serviceUtils';
import {api} from '../utils/services';
import auth from '../utils/auth';
import {getFilterAsQueryString} from '../utils/routeUtils';
import {isArray} from 'lodash';
import {
  DELETE_ACTIVITY_PLAN_RECHARGE,
  FETCH_ACTIVITY_INSIGHT_CONFIGS, FETCH_ACTIVITY_PLAN_RECHARGES, FETCH_CACHE_ACTIVITY_PLAN_RECHARGES,
  RESET_ACTIVITY_INSIGHT,
  SORT_ACTIVITY_INSIGHT
} from "../constants/actionTypes";

export const resetActivitiesData = (fields) => {
  return {
    type: types.RESET_ACTIVITIES_DATA,
    fields
  };
};
export const resetSActivitiesData = (fields) => {
  return {
    type: types.RESET_S_ACTIVITIES_DATA,
    fields
  };
};
export const resetOffersData = (fields) => {
  return {
    type: types.RESET_OFFERS_DATA,
    fields
  };
};
export const resetSOffersData = (fields) => {
  return {
    type: types.RESET_S_OFFERS_DATA,
    fields
  };
};

export const resetActivityQuestions = (fields) => {
  return {
    type: types.RESET_ACTIVITY_QUESTIONS,
    fields
  };
};
export const resetSQuestions = (fields) => {
  return {
    type: types.RESET_S_QUESTIONS,
    fields
  };
};
export const resetFocusAreaQuestions = (fields) => {
  return {
    type: types.RESET_FOCUS_AREA_ACTIVITY_QUESTIONS,
    fields
  };
};

export const showActivityLinkCopiedSnackbar = (id) => {
  return {
    type: types.SHOW_ACTIVITY_LINK_COPIED_SNACKBAR,
    id
  };
}
export const showActivityModal = (id) => {
  return {
    type: types.SHOW_ACTIVITY_MODAL,
    id
  };
}

export const hideActivityModal = () => {
  return {
    type: types.HIDE_ACTIVITY_MODAL
  };
}

export const showDisplayCriteriaModal = (id) => {
  return {
    type: types.SHOW_DISPLAY_CRITERIA_MODAL,
    id
  };
}

export const hideDisplayCriteriaModal = (id) => {
  return {
    type: types.HIDE_DISPLAY_CRITERIA_MODAL,
    id
  };
}

export const updateActivityData = (field, value) => {
  return {
    type: types.UPDATE_ACTIVITY_DATA,
    field,
    value
  };
}

export const validateActivityData = (field, value) => {
  return {
    type: types.VALIDATE_ACTIVITY_DATA,
    field,
    value
  }
};

export const showActivityCommentModal = (id) => {
  return {
    type: types.SHOW_ACTIVITY_COMMENT_MODAL,
    id
  };
}

export const hideActivityCommentModal = () => {
  return {
    type: types.HIDE_ACTIVITY_COMMENT_MODAL
  };
}

export const updateActivityCommentData = (field, value) => {
  return {
    type: types.UPDATE_ACTIVITY_COMMENT_DATA,
    field,
    value
  };
}

export const validateActivityCommentData = (field, value) => {
  return {
    type: types.VALIDATE_ACTIVITY_COMMENT_DATA,
    field,
    value
  }
};

export const fetchCacheActivities = (id, search, page, perPage, isLoadingDisabled, inDetail, ft) => {
  return {
    type: types.FETCH_CACHE_ACTIVITIES,
  }
};
export const fetchOpenActivities = (id, search, page, perPage, isLoadingDisabled, inDetail, ft) => {
  return {
    type: types.FETCH_CACHE_ACTIVITIES,
  }
};
export const fetchActivities = (id, search, page, perPage, isLoadingDisabled, inDetail, ft) => {
  return wrapCall(
    api.get(`/userB/activities?page=${page}&perPage=${perPage || 10}${id ? '&businessId=' + id : ''}${search ? '&search=' + search : ''}${inDetail ? '&inDetail=' + inDetail : ''}${ft ? '&' + getFilterAsQueryString(ft) : ''}`),
    types.FETCH_ACTIVITIES,
    {id, isLoadingDisabled}
  );
};
export const fetchSActivities = (id, search, page, perPage, isLoadingDisabled, inDetail, ft) => {
  return wrapCall(
    api.get(`/userB/activities?page=${page}&perPage=${perPage || 10}${id ? '&businessId=' + id : ''}${search ? '&search=' + search : ''}${inDetail ? '&inDetail=' + inDetail : ''}${ft ? '&' + getFilterAsQueryString(ft) : ''}`),
    types.FETCH_S_ACTIVITIES,
    { id, isLoadingDisabled }
  );
};
export const fetchOffers = (id, search, page, perPage, isLoadingDisabled, inDetail, ft) => {
  return wrapCall(
    api.get(`/userB/activities?types=2&page=${page}&perPage=${perPage || 10}${id ? '&businessId=' + id : ''}${search ? '&search=' + search : ''}${inDetail ? '&inDetail=' + inDetail : ''}${ft ? '&' + getFilterAsQueryString(ft) : ''}`),
    types.FETCH_OFFERS,
    { id, isLoadingDisabled }
  );
};
export const fetchSOffers = (id, search, page, perPage, isLoadingDisabled, inDetail, ft) => {
  return wrapCall(
    api.get(`/userB/activities?types=2&page=${page}&perPage=${perPage || 10}${id ? '&businessId=' + id : ''}${search ? '&search=' + search : ''}${inDetail ? '&inDetail=' + inDetail : ''}${ft ? '&' + getFilterAsQueryString(ft) : ''}`),
    types.FETCH_S_OFFERS,
    { id, isLoadingDisabled }
  );
};

export const fetchActivity = (id, inDetail, type, rechargeId, noLoading) => {
  return wrapCall(
    api.get(`/userB/activities/${id}?${inDetail ? 'inDetail=' + inDetail : ''}${rechargeId ? '&rId=' + rechargeId : ''}${type ? '&type=' + type : ''}`),
    noLoading ? types.FETCH_ACTIVITY_NO_LOADING : types.FETCH_ACTIVITY,
    {id}
  );
};

export const fetchCacheActivityPlanRecharges = () => {
  return {
    type: types.FETCH_CACHE_ACTIVITY_PLAN_RECHARGES,
  }
};

export const fetchActivityPlanRecharges = (page, perPage = 20) => {
  return wrapCall(
    api.get(`/userB/activityPlanRecharges?page=${page}&perPage=${perPage}`),
    types.FETCH_ACTIVITY_PLAN_RECHARGES
  );
};

export const fetchActivityPlanRecharge = (id) => {
  return wrapCall(
    api.get(`/userB/activityPlanRecharges/${id}`),
    types.FETCH_ACTIVITY_PLAN_RECHARGE,
    {id}
  );
};

export const downloadActivityPlanRecharge = (id, onDownloadProgress) => {
  return wrapCall(
    api.get(`/userB/activityPlanRecharges/downloadInvoice/${id}`, null, {responseType: 'blob', onDownloadProgress: onDownloadProgress}),
    types.DOWNLOAD_ACTIVITY_PLAN_RECHARGE,
    {id}
  );
};

export const deleteActivityPlanRecharge = (id) => {
  return wrapCall(
    api.delete(`/userB/activityPlanRecharges/${id}`),
    types.DELETE_ACTIVITY_PLAN_RECHARGE,
    {id}
  );
};

export const createActivity = (data) => {
  let formData = new FormData();
  for (let key in data) {
    if (isArray(data[key])) {
      const arr = data[key];
      for (var i = 0; i < arr.length; i++) {
        formData.append(`${key}[]`, arr[i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  formData.append('token', auth.getToken());

  return wrapCall(
    api.post(`/userB/activities`, formData, {'Content-Type': 'multipart/form-data'}),
    types.CREATE_ACTIVITY
  );
};

export const toggleActivityState = (id, newState, aType) => {
  return wrapCall(
    api.post(`/userB/activities/state/${id}`, {state: newState}),
    types.TOGGLE_ACTIVITY_STATE,
    {id, newState, aType}
  );
};

export const fetchActivityInsightConfigs = (id, aType, showRating) => {
  return wrapCall(
    api.get(`/userB/activityInsightConfigs/${id}?aType=${aType}${showRating ? '&sr=1' : ''}`),
    types.FETCH_ACTIVITY_INSIGHT_CONFIGS,
    {id}
  );
};

export const fetchActivityInsight = (id, aType, chartId, focusId, filters) => {
  return wrapCall(
    api.get(`/userB/activityInsight/${id}?c=${chartId}&a=${focusId}&aType=${aType}${filters ? '&' + getFilterAsQueryString(filters) : ''}`),
    types.FETCH_ACTIVITY_INSIGHT,
    {id}
  );
};

export const resetActivityInsight = () => {
  return {type: types.RESET_ACTIVITY_INSIGHT};
};

export const sortActivityInsight = (dataIndex, sortBy) => {
  return {type: types.SORT_ACTIVITY_INSIGHT, dataIndex, sortBy};
};

export const toggleZeroInActivityInsight = (dataIndex) => {
  return {type: types.TOGGLE_ZERO_IN_ACTIVITY_INSIGHT, dataIndex};
};

export const toggleShowHideQnsInActivityInsight = (dataIndex) => {
  return {type: types.TOGGLE_SHOW_HIDE_QNS_IN_ACTIVITY_INSIGHT, dataIndex};
};

export const fetchActivityWinners = (id, page = 1, perPage = 10) => {
  return wrapCall(
    api.get(`/userB/activities/winners/${id}?page=${page}&perPage=${perPage}`),
    types.FETCH_ACTIVITY_WINNERS,
    {id}
  );
};

export const fetchActivityPlayerStats = (id, page = 1, perPage = 10) => {
  return wrapCall(
    api.get(`/userB/activities/players/${id}?page=${page}&perPage=${perPage}`),
    types.FETCH_ACTIVITY_WINNERS,
    {id}
  );
};

export const reportActivity = (data) => {
  const id = data.id;
  return wrapCall(
    api.post(`/userB/spammedActivities`, data),
    types.ACTIVITY_REPORT,
    {id}
  );
};

export const createActivityLike = (id, like) => {
  return wrapCall(
    api.post(`/userB/activityLikes`, {activityId: id, like}),
    types.CREATE_ACTIVITY_LIKE,
    {id, like}
  );
};

export const updateActivity = (id, data) => {
  let formData = new FormData();
  for (let key in data) {
    if (isArray(data[key])) {
      const arr = data[key];
      for (var i = 0; i < arr.length; i++) {
        formData.append(`${key}[]`, arr[i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  formData.append('token', auth.getToken());
  //formData.append('_method', 'PUT');

  return wrapCall(
    api.post(`/userB/activities/${id}`, formData, {'Content-Type': 'multipart/form-data'}),
    types.UPDATE_ACTIVITY
  );
}

export const updateActivityDisplayCriteria = (id, data) => {
  return wrapCall(
    api.post(`/userB/activities/criteria/${id}`, data),
    types.UPDATE_ACTIVITY_DISPLAY_CRITERIA
  );
}

export const deleteActivity = (id, aType) => {
  return wrapCall(
    api.delete(`/userB/activities/${id}`),
    types.DELETE_ACTIVITY,
    {id, aType}
  );
};

export const declareCampaignWinners = (id, data) => {
  return wrapCall(
    api.post(`/userB/activities/processWinners/${id}`, data),
    types.DECLARE_ACTIVITY_WINNERS,
    {id, aType: data.type}
  );
};

export const downloadActivityPostBusiness = (id, onDownloadProgress) => {
  return wrapCall(
    api.get(`/userB/activityReviews/downloads/${id}`, null, {responseType: 'blob', onDownloadProgress: onDownloadProgress}),
    types.DOWNLOAD_ACTIVITY_REVIEW,
    {id}
  );
};

export const resetActivitiesError = () => {
  return {
    type: types.RESET_ACTIVITIES_ERROR,
  };
};

export const fetchActivityComments = (id, search, page, perPage, isLoadingDisabled, filters) => {
  return wrapCall(
    api.get(`/userB/activityComments?page=${page}&perPage=${perPage || 10}${id ? '&activityId=' + id : ''}${search ? '&search=' + search : ''}${filters ? '&' + getFilterAsQueryString(filters) : ''}`),
    types.FETCH_ACTIVITY_COMMENTS,
    {id, isLoadingDisabled}
  );
};

export const createActivityComment = (data, createdBy) => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append('token', auth.getToken());

  return wrapCall(
    api.post(`/userB/activityComments`, formData, {'Content-Type': 'multipart/form-data'}),
    types.CREATE_ACTIVITY_COMMENT,
    {comment: data, createdBy}
  );
};

export const deleteActivityComment = (id) => {
  return wrapCall(
    api.delete(`/userB/activityComments/${id}`),
    types.DELETE_ACTIVITY_COMMENT,
    {id}
  );
};

export const createActivityCommentLike = (id, like) => {
  return wrapCall(
    api.post(`/userB/activityCommentLikes`, {commentId: id, like}),
    types.CREATE_ACTIVITY_COMMENT_LIKE,
    {id, like}
  );
};

export const fetchActivityQuestionsDropDown = (search, page = 1, perPage = 20, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityQuestions?withOptions=1&page=${page}&perPage=${perPage}${search ? '&search=' + search : ''}`),
    types.FETCH_ACTIVITY_QUESTIONS_DROP_DOWN,
    { isLoadingDisabled }
  );
}
export const fetchActivityQuestions = (search, page = 1, perPage = 20, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityQuestions=1&page=${page}&perPage=${perPage}${search ? '&search=' + search : ''}`),
    types.FETCH_ACTIVITY_QUESTIONS,
    { isLoadingDisabled }
  );
}
export const fetchSQuestions = (search, page = 1, perPage = 20, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityQuestions?page=${page}&perPage=${perPage}${search ? '&search=' + search : ''}`),
    types.FETCH_S_QUESTIONS,
    { isLoadingDisabled }
  );
}
export const fetchFocusAreaQuestions = (search, page = 1, perPage = 20, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityQuestions?page=${page}&perPage=${perPage}${search ? '&search=' + search : ''}`),
    types.FETCH_FOCUS_AREA_ACTIVITY_QUESTIONS,
    { isLoadingDisabled }
  );
}

export const fetchActivityQuestionOptions = (id, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityQuestionOptions?activityId=${id}`),
    types.FETCH_ACTIVITY_QUESTION_OPTIONS,
    { id, isLoadingDisabled }
  );
}
export const fetchSQuestionOptions = (id, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityQuestionOptions?activityId=${id}`),
    types.FETCH_S_QUESTION_OPTIONS,
    { id, isLoadingDisabled }
  );
}

export const showActivityAnswersModal = (id) => {
  return {
    type: types.SHOW_ACTIVITY_ANSWERS_MODAL,
    id
  };
}
export const hideActivityAnswersModal = () => {
  return {
    type: types.HIDE_ACTIVITY_ANSWERS_MODAL
  };
}
export const fetchActivityAnswers = (id, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityQuestionOptions/distribution?activityId=${id}`),
    types.FETCH_ACTIVITY_ANSWERS,
    { id, isLoadingDisabled }
  );
}

export const showActivityRatingsModal = (id) => {
  return {
    type: types.SHOW_ACTIVITY_RATINGS_MODAL,
    id
  };
}
export const hideActivityRatingsModal = () => {
  return {
    type: types.HIDE_ACTIVITY_RATINGS_MODAL
  };
}
export const fetchActivityRatings = (id, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/userB/activityComments/ratingDistribution/${id}`),
    types.FETCH_ACTIVITY_RATINGS,
    { id, isLoadingDisabled }
  );
}

export const manageActivity = (id, data, onUploadProgress) => {
  let formData = new FormData();
  for (let key in data) {
    if (isArray(data[key])) {
      const arr = data[key];
      for (var i = 0; i < arr.length; i++) {
        formData.append(`${key}[]`, arr[i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  formData.append('token', auth.getToken());

  const url = id ? `/userB/activities/${id}` : `/userB/activities`;
  return wrapCall(
    api.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}, onUploadProgress: onUploadProgress}),
    types.MANAGE_ACTIVITY
  );
};

export const resetManageActivityErrMsg = () => {
  return {
    type: types.RESET_MANAGE_ACTIVITY_ERROR_MESSAGE
  }
};

export const addNewRewardInActivity = (data, onUploadProgress) => {
  let formData = new FormData();
  for (let key in data) {
    if (isArray(data[key])) {
      const arr = data[key];
      for (let i = 0; i < arr.length; i++) {
        formData.append(`${key}[]`, arr[i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  formData.append('token', auth.getToken());

  const url = `/userB/activities`;
  return wrapCall(
    api.post(url, formData, {'Content-Type': 'multipart/form-data', onUploadProgress: onUploadProgress}),
    types.ADD_REWARD_IN_ACTIVITY
  );
};

export const fetchActivityWinRewards = (search, usageTypes) => {
  return wrapCall(
    api.get(`/userB/activities?page=1&perPage=100&forReward=1&copy=1&search=${search || ''}${usageTypes ? '&usageTypes='+ usageTypes : ''}`),
    types.FETCH_ACTIVITY_WIN_REWARDS,
    {search}
  );
};

export const fetchActivityAnswerDistributionsForBusiness = (id) => {
  return wrapCall(
    api.get(`/userB/activityQuestionOptions/distribution`, {activityId: id}),
    types.FETCH_FUN_ANSWER_DISTRIBUTIONS,
    {id}
  );
};

export const fetchActivityReviews = (id, page, perPage = 10) => {
  return wrapCall(
    api.get(`/userB/activityReviews?page=${page}&perPage=${perPage}&activityId=${id}`),
    types.GET_ACTIVITY_REVIEWS,
    {id}
  );
};
