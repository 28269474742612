import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import auth from "../utils/auth";
import {connect} from "react-redux";
import {PathAccount, PathHome, PathLogin} from "src/js/components/routes/NavHelper";
import {isUserAccountPending} from "src/js/utils/AppUtils";

const NotFound = (props) => {

  const redirect = () => {
    setTimeout(() => {
      const isAuthed = auth.isAuthenticated();
      if (isAuthed) {
        if (isUserAccountPending(props.user)) {
          props.history.replace({
            pathname: PathAccount,
            search: props.history.location.search
          });
        } else {
          props.history.replace(PathHome);
        }
      } else {
        props.history.replace(PathHome);
      }
    }, 100);
  }

  useEffect(() => {
    redirect();
  }, []);

  return (
    <div />
  )
};

NotFound.propTypes = {
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.admin.user,
  };
}

export default connect(mapStateToProps)(NotFound);
