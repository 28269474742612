import {Scene} from 'phaser';
import {EventBus} from "src/gameTapTapShots/EventBus";
import {addTimerEvent} from "src/game/GameHelper";

export class MainMenu extends Scene {
  constructor() {
    super('MainMenu');
  }

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
  }

  onEventSimulate = () => {
    this.autoSimulation = true;
    addTimerEvent(this, 1000, () => {
      this.autoPlay();
    });
  }

  autoPlay = () => {
    // if (!this.gameEnded) {
    //   this.onClick();
    // }
  }

  cleanup = () => {
    EventBus.off('simulate', this.onEventSimulate);
    this.input.off('pointerdown');
    if (this.audioGameBg) {
      this.audioGameBg.stop();
    }
    this.time.removeAllEvents();
    this.events.off('destroy');
  }

  create() {
    this.events.on('shutdown', this.cleanup);
    this.events.on('destroy', this.cleanup);
    EventBus.on('mute', this.onEventMute);
    EventBus.on('simulate', this.onEventSimulate);

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    if (!this.game.options.simulation) {
      this.audioGameBg = this.sound.add('audio_game_bg', {loop: true})
        .setMute(this.game.options.mute);
      this.audioGameBg.play();
    }

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const adImage = this.add.image(gameWidth / 2, (gameWidth - 48) / 2 + 180, 'adImage1');
    adImage.setDisplaySize(gameWidth - 48, gameWidth - 48);
    adImage.setAlpha(0.8);
    // if (this.game.options && this.game.options.canPlay) {
    //   adImage.setAlpha(0.2);
    // }

    // this.add.text(gameWidth/2, gameHeight/2-360, 'TAP TAP', {
    //     // fontFamily: 'Arial',
    //     fontSize: 105, color: '#ffffff',
    //     stroke: '#48464F', strokeThickness: 12,
    //     align: 'center'
    // }).setOrigin(0.5);
    // this.add.text(gameWidth/2, gameHeight/2-240, 'SHOTS', {
    //     // fontFamily: 'Arial',
    //     fontSize: 150, color: '#ffffff',
    //     stroke: '#48464F', strokeThickness: 12,
    //     align: 'center'
    // }).setOrigin(0.5);

    this.objectScale = 1.4;
    const hoopPosition = this.getHoopPosition();

    // this.platforms = this.physics.add.staticGroup();
    // this.platforms.create(gameWidth, gameHeight-10, 'ground');
    // this.platforms.create(0, gameHeight-10, 'ground');

    this.hoop1 = this.add.image(hoopPosition.hoop1.x, hoopPosition.hoop1.y, 'hoop1');
    this.hoop2 = this.add.image(hoopPosition.hoop2.x, hoopPosition.hoop2.y, 'hoop2');
    this.hoop3 = this.add.image(hoopPosition.hoop3.x, hoopPosition.hoop3.y, 'hoop3');
    this.hoop5 = this.add.image(hoopPosition.hoop5.x, hoopPosition.hoop5.y, 'hoop5');
    this.hoop6 = this.add.image(hoopPosition.hoop6.x, hoopPosition.hoop6.y, 'hoop6');

    this.hoopb1 = this.add.image(hoopPosition.hoopb1.x, hoopPosition.hoopb1.y, 'hoopb1');
    this.hoopb2 = this.add.image(hoopPosition.hoopb2.x, hoopPosition.hoopb2.y, 'hoopb2');
    this.hoopb3 = this.add.image(hoopPosition.hoopb3.x, hoopPosition.hoopb3.y, 'hoopb3');

    this.hoop4 = this.physics.add.staticSprite(hoopPosition.hoop4.x, hoopPosition.hoop4.y, 'hoop4');
    this.hoopNet = this.physics.add.staticSprite(hoopPosition.hoopNet.x, hoopPosition.hoopNet.y, 'hoopNet');

    this.ball = this.physics.add.staticSprite(gameWidth * 0.4, gameHeight * 0.5, 'ballC');

    if (!this.game.options.simulation) {
      const stringValTapToDunk = this.game.options.locale && this.game.options.locale == 'ar' ? 'ضغط الشاشة لبدء التحدي' : 'TAP TO DUNK';
      const text = this.add.text(gameWidth * 0.5, 180, stringValTapToDunk, {
        // fontFamily: 'Arial',
        fontSize: 60, color: '#ffffff',
        stroke: '#48464F', strokeThickness: 4,
        align: 'center'
      }).setOrigin(0.5);
      text.setAlpha(0.3);
      if (this.game.options && this.game.options.canPlay) {
        text.setAlpha(0.8);
      }
    }

    this.input.once('pointerdown', () => {
      this.scene.start('Game');
    });

    this.setObjectBounds(this.hoop1, hoopPosition.hoop1);
    this.setObjectBounds(this.hoop2, hoopPosition.hoop2);
    this.setObjectBounds(this.hoop3, hoopPosition.hoop3);
    this.setObjectBounds(this.hoop4, hoopPosition.hoop4);
    this.setObjectBounds(this.hoop5, hoopPosition.hoop5);
    this.setObjectBounds(this.hoop6, hoopPosition.hoop6);
    this.setObjectBounds(this.hoopb1, hoopPosition.hoopb1);
    this.setObjectBounds(this.hoopb2, hoopPosition.hoopb2);
    this.setObjectBounds(this.hoopb3, hoopPosition.hoopb3);
    this.setObjectBounds(this.hoopNet, hoopPosition.hoopNet);
    this.setObjectScale(this.ball);
  }

  onClick = () => {
    this.scene.start('Game');
  }

  getHoopPosition = () => {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.hoopHeight = gameHeight * 0.5;
    let scaleY = this.objectScale;
    if (this.objectScale === 1.5) {
      scaleY = this.objectScale * .8;
    } else if (this.objectScale === 1.4) {
      scaleY = this.objectScale * .88;
    } else if (this.objectScale === 1.2) {
      scaleY = this.objectScale * .92;
    } else if (this.objectScale === .75) {
      scaleY = this.objectScale * 1.15;
    }
    return {
      flipX: false,

      hoop1: {x: gameWidth - 200 * this.objectScale, y: Math.ceil(this.hoopHeight + 71 + 10 - 10 * scaleY), width: 16, height: 10},
      hoop2: {x: gameWidth - 200 * this.objectScale, y: Math.floor(this.hoopHeight + 82 - 12 + 12 * scaleY), width: 16, height: 12},
      hoop3: {x: gameWidth - 127 * this.objectScale, y: Math.ceil(this.hoopHeight + 68 + 16 - 16 * scaleY), width: 130, height: 16},
      hoop4: {x: gameWidth - 127 * this.objectScale, y: Math.floor(this.hoopHeight + 85 - 18 + 18 * scaleY), width: 130, height: 18},
      hoopNetScore: {x: gameWidth - 127 * this.objectScale, y: Math.floor(this.hoopHeight + 85 + 18 * this.objectScale - 18 + 18 * scaleY), width: 130, height: 18},

      hoop5: {x: gameWidth - 54 * this.objectScale, y: Math.ceil(this.hoopHeight + 71 + 10 - 10 * scaleY), width: 16, height: 10},
      hoop6: {x: gameWidth - 54 * this.objectScale, y: Math.floor(this.hoopHeight + 82 - 12 + 12 * scaleY), width: 16, height: 12},

      hoopb1: {x: gameWidth - 40 * this.objectScale, y: this.hoopHeight + 77, width: 12, height: 12},
      hoopb2: {x: gameWidth - 26 * this.objectScale, y: this.hoopHeight, width: 16, height: 240},
      hoopb3: {x: gameWidth - 9 * this.objectScale, y: this.hoopHeight + 77, width: 18, height: 28},
      hoopNet: {x: gameWidth - 127 * this.objectScale, y: Math.floor(this.hoopHeight + 120 - 76 + 76 * scaleY), width: 155, height: 76},
      hoopNetHidden: {x: gameWidth - 127 * this.objectScale, y: this.hoopHeight + 85 + 64 * this.objectScale - 18 + 18 * scaleY, width: 130, height: 18},
    }
  }

  setObjectBounds = (object, bounds) => {
    object.x = bounds.x;
    object.y = bounds.y;
    object.setScale(this.objectScale);
    if (object.body) {
      object.body.x = bounds.x - bounds.width * this.objectScale / 2;
      object.body.y = bounds.y - bounds.height * this.objectScale / 2;
      object.body.width = object.width * this.objectScale;
      object.body.height = object.height * this.objectScale;
    }
  }

  setObjectScale = (object) => {
    object.setScale(this.objectScale + .5);
  }

}
