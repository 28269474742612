import React from "react";

const View = ({ style = {}, className, children, ...otherProps }) => {
  const combinedStyle = !className
    ? {
      display: 'flex',
      flexDirection: style && style.flexDirection ? style.flexDirection : 'column',
      ...style,
    }
    : style;

  return (
    <div {...otherProps} className={className} style={combinedStyle}>
      {children}
    </div>
  );
};

export default React.memo(View);
