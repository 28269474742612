import {Scene} from 'phaser';

export class GPRPreloader extends Scene {
  constructor() {
    super('GPRPreloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    //  We loaded this image in our GFKBoot Scene, so we can display it here
    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const barWidth = gameWidth - 96;
    const innerBarWidth = barWidth - 8;

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight / 2, barWidth, 32).setStrokeStyle(1, 0xEEEEEE);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight / 2, 4, 28, 0xEEEEEE);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {

      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);

    });
  }

  preload() {
    let bgAudioUrl = null;
    let rawStrikerImageUrl = null;
    let ballImageUrl = null;
    let sawImageUrl = null;
    let adImageUrl = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              rawStrikerImageUrl = item.url;
            } else if (item.number === 1) {
              ballImageUrl = item.url;
            } else if (item.number === 2) {
              sawImageUrl = item.url;
            } else if (item.number === 3) {
              adImageUrl = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('assets/gamePacManRush');

    this.load.image('rawStriker', rawStrikerImageUrl || 'striker.png');
    this.load.image('rawBall', ballImageUrl || 'ball.png');
    this.load.image('saw', sawImageUrl || 'saw.png');
    // this.load.image('saw', 'saw.png');
    // this.load.image('sawIn', sawImageUrl || 'saw.png');
    this.load.image('adImage1', adImageUrl || 'adImage.png');

    this.load.image('pacman', 'pacman.png');
    this.load.multiatlas('pacmanS', 'pacman.json');

    if (!options.simulation) {
      this.load.audio('audio_bg', bgAudioUrl || 'audios/audio_bg.m4a');
      this.load.audio('audio_crash', 'audios/audio_crash.mp3');
      this.load.audio('audio_eat', 'audios/audio_eat.mp3');
      this.load.audio('audio_game_end', 'audios/audio_game_end.wav');
      this.load.audio('audio_game_win', 'audios/audio_game_win.mp3');
    }
  }

  create() {
    const renderTexture = this.make.renderTexture({x: 45, y: 45, width: 90, height: 90}, false);
    const maskShape = this.make.graphics({}, false)
      .fillCircle(45, 45, 45);
    const image = this.add.image(45, 45, 'rawStriker')
      .setDisplaySize(90, 90)
      .setVisible(false);
    image.setMask(maskShape.createGeometryMask());
    renderTexture.draw(image, 45, 45, 1);
    renderTexture.saveTexture('striker');
    maskShape.destroy(true);
    image.destroy(true);
    renderTexture.destroy(true);

    const renderTextureBall = this.make.renderTexture({x: 45, y: 45, width: 90, height: 90}, false);
    const maskShapeBall = this.make.graphics({}, false)
      .fillCircle(45, 45, 45);
    const imageBall = this.add.image(45, 45, 'rawBall')
      .setDisplaySize(90, 90)
      .setVisible(false);
    imageBall.setMask(maskShapeBall.createGeometryMask());
    renderTextureBall.draw(imageBall, 45, 45, 1);
    renderTextureBall.saveTexture('ball');
    maskShapeBall.destroy(true);
    imageBall.destroy(true);
    renderTextureBall.destroy(true);

    this.scene.start('GPRMainMenu');
    // this.scene.start('GPRGame');
  }
}
