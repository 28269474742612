import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';

import auth from '../../utils/auth';
import {connect} from "react-redux";
import {PrivateTopRoute} from "./PrivateTopRoute";
import AdminHome from "src/js/components/admin/AdminHome";
import AdminStandalone from "src/js/components/admin/AdminStandalone";
import {getRedirectSearch, PathAccount} from "src/js/components/routes/NavHelper";
import {isUserAccountPending} from "src/js/utils/AppUtils";

function PrivatePlayRoute({component: Component, ...rest}) {
  const isAuthed = auth.isAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        !isUserAccountPending(rest.user) ?
          (isAuthed ?
            <AdminHome history={props.history}>
              <Component {...props} />
            </AdminHome>
            : <AdminStandalone history={props.history}>
              <Component {...props} />
            </AdminStandalone>
          )
          : <Redirect to={{pathname: PathAccount, search: getRedirectSearch(props.history.location)}} />
      }
    />
  )
}

PrivatePlayRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  authed: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: state.admin.user,
  };
}

export default connect(mapStateToProps)(PrivatePlayRoute);
