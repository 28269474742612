import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';

import auth from '../../utils/auth';

import AdminHome from '../admin/AdminHome';
import {connect} from "react-redux";
import {getRedirectSearch, PathAccount} from "src/js/components/routes/NavHelper";
import {isUserAccountPending} from "src/js/utils/AppUtils";

export function PublicDrawerRoute ({component: Component, ...rest}) {
  const isAuthed = auth.isAuthenticated();
  const user = auth.getUser();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthed && isUserAccountPending(user) ?
          <Redirect to={{pathname: PathAccount, search: getRedirectSearch(props.history.location)}} />
          : <AdminHome history={props.history}>
            <Component {...props} />
          </AdminHome>
        }
    />
  )
}

PublicDrawerRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  authed: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: state.admin.user,
  };
}

export default connect(mapStateToProps)(PublicDrawerRoute);
