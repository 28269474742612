import {Scene} from 'phaser';

export class GSUPreloader extends Scene {
  constructor() {
    super('GSUPreloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    //  We loaded this image in our GFKBoot Scene, so we can display it here
    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const barWidth = gameWidth - 96;
    const innerBarWidth = barWidth - 8;

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight / 2, barWidth, 32).setStrokeStyle(1, 0xEEEEEE);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight / 2, 4, 28, 0xEEEEEE);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {
      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);
    });
  }

  preload() {
    let bgAudioUrl = null;
    let boxTop1ImageUrl = null;
    let boxTop2ImageUrl = null;
    let adImageUrl = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              boxTop1ImageUrl = item.url;
            } else if (item.number === 1) {
              boxTop2ImageUrl = item.url;
            } else if (item.number === 2) {
              adImageUrl = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('assets/gameSnakeUp');

    this.load.image('boxTop1', boxTop1ImageUrl || 'boxTop1.png');
    this.load.image('boxTop2', boxTop2ImageUrl || 'boxTop2.png');
    this.load.image('adImage1', adImageUrl || 'adImage.png');
    this.load.image('box', 'box.png');
    this.load.image('boxTopMinus', 'boxTopMinus.png');

    this.load.image('snakeFace', 'snakeFace.png');
    this.load.image('snakeBody1', 'snakeBody1.png');
    this.load.image('snakeBody2', 'snakeBody2.png');
    this.load.image('plus', 'plus.png');
    this.load.image('minus', 'minus.png');
    this.load.image('finish', 'finish.png');

    if (!options.simulation) {
      this.load.audio('audio_bg', bgAudioUrl || 'audios/audio_bg.m4a');
      this.load.audio('audio_minus', 'audios/audio_minus.mp3');
      this.load.audio('audio_plus', 'audios/audio_plus.mp3');
      this.load.audio('audio_game_end', 'audios/audio_game_end.wav');
      this.load.audio('audio_game_win', 'audios/audio_game_win.mp3');
    }
  }

  create() {
    const boxWidth = 112;
    const boxHeight = boxWidth;
    const topSize = boxWidth*.85;

    const renderTexture1 = this.make.renderTexture({x: 0, y: 0, width: boxWidth, height: boxHeight}, false);
    const image11 = this.add.image(0, 0, 'box')
      .setDisplaySize(boxWidth, boxHeight)
      .setVisible(false);
    const image12 = this.add.image(0, 0, 'boxTop1')
      .setDisplaySize(topSize, topSize)
      .setVisible(false);
    renderTexture1.draw(image11, boxWidth/2, boxHeight/2, 1);
    renderTexture1.draw(image12, boxWidth/2, boxHeight/2 - 3, 1);
    renderTexture1.saveTexture('box1');
    image11.destroy(true);
    image12.destroy(true);
    renderTexture1.destroy(true);

    const renderTexture2 = this.make.renderTexture({x: 0, y: 0, width: boxWidth, height: boxHeight}, false);
    const image21 = this.add.image(0, 0, 'box')
      .setDisplaySize(boxWidth, boxHeight)
      .setVisible(false);
    const image22 = this.add.image(0, 0, 'boxTop2')
      .setDisplaySize(topSize, topSize)
      .setVisible(false);
    renderTexture2.draw(image21, boxWidth/2, boxHeight/2, 1);
    renderTexture2.draw(image22, boxWidth/2, boxHeight/2 - 3, 1);
    renderTexture2.saveTexture('box2');
    image21.destroy(true);
    image22.destroy(true);
    renderTexture2.destroy(true);

    const renderTexture3 = this.make.renderTexture({x: 0, y: 0, width: boxWidth, height: boxHeight}, false);
    const image31 = this.add.image(0, 0, 'box')
      .setDisplaySize(boxWidth, boxHeight)
      .setVisible(false);
    const image32 = this.add.image(0, 0, 'boxTopMinus')
      .setDisplaySize(topSize, topSize)
      .setVisible(false);
    renderTexture3.draw(image31, boxWidth/2, boxHeight/2, 1);
    renderTexture3.draw(image32, boxWidth/2, boxHeight/2 - 3, 1);
    renderTexture3.saveTexture('boxMinus');
    image31.destroy(true);
    image32.destroy(true);
    renderTexture3.destroy(true);

    this.scene.start('GSUGame');
  }
}
