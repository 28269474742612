import {Scene} from 'phaser';

export class GFKPreloader extends Scene {
  constructor() {
    super('GFKPreloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    //  We loaded this image in our GFKBoot Scene, so we can display it here
    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const barWidth = gameWidth - 96;
    const innerBarWidth = barWidth - 8;

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight / 2, barWidth, 32).setStrokeStyle(1, 0xEEEEEE);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight / 2, 4, 28, 0xEEEEEE);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {

      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);

    });
  }

  preload() {
    let bgAudioUrl = null;
    let ballImageUrl = null;
    let saver1ImageUrl = null;
    let saver2ImageUrl = null;
    let scorer1ImageUrl = null;
    let adImageUrl = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              ballImageUrl = item.url;
            } else if (item.number === 1) {
              saver1ImageUrl = item.url;
            } else if (item.number === 2) {
              saver2ImageUrl = item.url;
            } else if (item.number === 3) {
              scorer1ImageUrl = item.url;
            } else if (item.number === 4) {
              adImageUrl = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('assets/gameFreeKicks');

    this.load.image('ballRaw', ballImageUrl || 'ball.png');
    this.load.image('saver', saver1ImageUrl || 'saver.png');
    this.load.image('rawSaver_1', saver2ImageUrl || 'saver_1.png');
    this.load.image('rawScorer', scorer1ImageUrl || 'scorer.png');
    this.load.image('adImage1', adImageUrl || 'adImage.png');

    this.load.image('ballShadow', 'ballShadow.png');
    this.load.image('goalPost', 'goalPost.png');
    this.load.image('goalNet', 'goalNet.png');
    this.load.image('goalLine', 'goalLine.png');
    this.load.image('goalKeeper', 'goalKeeper.png');
    this.load.image('goalSaver1', 'goalSaver1.png');
    this.load.image('plusOne', 'plusOne.png');
    this.load.image('minusOne', 'minusOne.png');

    if (!options.simulation) {
      this.load.audio('audio_bg', bgAudioUrl || 'audios/audio_bg.m4a');
      this.load.audio('audio_whistle', 'audios/audio_whistle.m4a');
      this.load.audio('audio_kick', 'audios/audio_kick.mp3');
      this.load.audio('audio_hit_player', 'audios/audio_hit_player.mp3');
      this.load.audio('audio_hit_pole', 'audios/audio_hit_pole.mp3');
      this.load.audio('audio_goal', 'audios/audio_goal.m4a');
      this.load.audio('audio_game_end', 'audios/audio_game_end.wav');
      this.load.audio('audio_game_win', 'audios/audio_game_win.mp3');
    }
  }

  create() {
    const renderTexture = this.make.renderTexture({x: 80, y: 80, width: 160, height: 160}, false);
    const maskShape = this.make.graphics({}, false)
      .fillCircle(80, 80, 80);
    const image = this.add.image(80, 80, 'ballRaw')
      .setDisplaySize(160, 160)
      .setVisible(false);
    image.setMask(maskShape.createGeometryMask());
    renderTexture.draw(image, 80, 80, 1);
    renderTexture.saveTexture('ball');
    image.destroy(true);
    renderTexture.destroy(true);

    const renderTexture_1 = this.make.renderTexture({x: 0, y: 0, width: 160, height: 224}, false);
    const image_1 = this.add.image(80, 80, 'rawSaver_1')
      .setDisplaySize(160, 160)
      .setVisible(false);
    const imageMinusOne = this.add.image(43, 32, 'minusOne')
      .setDisplaySize(87, 64)
      .setVisible(false);
    const text_1 = this.add.text(160, 0, '-1', {
      // fontFamily: 'Poppins',
      fontSize: 50,
      color: '#fff',
      stroke: '#333',
      align: 'center',
      strokeThickness: 4,
      backgroundColor: '#f00',
      padding: 8
    }).setOrigin(0.5)
      .setShadow(0, 4, 'rgba(0, 0, 0, 0.6)', 5);
    // renderTexture_1.draw(text_1, 80, 32, 1);
    renderTexture_1.draw(image_1, 80, 140, 1);
    renderTexture_1.draw(imageMinusOne, 80, 32, 1);
    renderTexture_1.saveTexture('saver_1');
    image_1.destroy(true);
    imageMinusOne.destroy(true);
    text_1.destroy(true);
    renderTexture_1.destroy(true);

    const renderTextureScorer = this.make.renderTexture({x: 0, y: 0, width: 160, height: 224}, false);
    const imageScorer = this.add.image(80, 80, 'rawScorer')
      .setDisplaySize(160, 160)
      .setVisible(false);
    const imagePlusOne = this.add.image(43, 32, 'plusOne')
      .setDisplaySize(87, 64)
      .setVisible(false);
    const text2xBg = this.add.rectangle(40, 32, 80, 64, 0x10ff00)
      .setStrokeStyle(2, 0x298023);
      // .setStrokeStyle(2, 0x000);
    const text2x = this.add.text(160, 0, '+1', {
      // fontFamily: 'Poppins',
      fontSize: 50, color: '#fff',
      stroke: '#333', strokeThickness: 4,
      align: 'center',
      // backgroundColor: '#1ce00e',
      padding: 8,
    }).setOrigin(0.5)
      .setShadow(0, 4, 'rgba(0, 0, 0, 0.6)', 5);
    // renderTextureScorer.draw(text2xBg, 80, 32, 1);
    renderTextureScorer.draw(imageScorer, 80, 140, 1);
    renderTextureScorer.draw(imagePlusOne, 80, 32, 1);
    renderTextureScorer.saveTexture('scorer');
    imageScorer.destroy(true);
    imagePlusOne.destroy(true);
    text2xBg.destroy(true);
    text2x.destroy(true);
    renderTextureScorer.destroy(true);

    this.scene.start('GFKMainMenu');
    // this.scene.start('GFKGame');
  }
}
